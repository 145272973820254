import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Add from '@mui/icons-material/Add';
import { FormControl, FormLabel, Input, Stack } from '@mui/joy';
import CorporateDesigns from './CorporateDesigns';
import { ToastContainer, toast } from 'react-toastify';

export default function CampaignModel({ designArray, setDesignArray, campaignData, createNewCampaign, setCampaignData }) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        New Campaign
      </Button>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: { lg: '800px', md: '90%', xs: '100%' },
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            height: "100vh",
            overflowY: "auto"
          }}
        >
          <ToastContainer />
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <p className=' font-semibold text-lg'>Add your campaign details</p>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (campaignData?.designs.length >= 1) {
                createNewCampaign()
                setOpen(false);
              }
              else
                toast.error("Add atleast 1 design")
            }}
          >
            <p className=' text-black'>Fields with {' '}<span className=' text-red-600'>*</span> are mandetory to fill.</p>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Campaign Title <span className=' text-red-600'>*</span></FormLabel>
                <Input
                  value={campaignData?.title}
                  onChange={(e) => setCampaignData({ ...campaignData, title: e.target.value })}
                  autoFocus required />
              </FormControl>
              <FormControl>
                <FormLabel>Campaign Description <span className=' text-red-600'>*</span></FormLabel>
                <Input
                  value={campaignData?.desc}
                  onChange={(e) => setCampaignData({ ...campaignData, desc: e.target.value })}
                  required />
              </FormControl>

              <div className=' grid grid-cols-2 gap-10'>

                {/* campaign Start Date */}
                <div>
                  <FormLabel>Start Date <span className=' text-red-600'>*</span></FormLabel>
                  <Input
                    value={campaignData?.startDate}
                    onChange={(e) => setCampaignData({ ...campaignData, startDate: e.target.value })}
                    type="date"
                    required
                    slotProps={{
                      input: {
                        // min: '2018-06-07',
                        // max: '2018-06-14',
                      },
                    }}
                  />
                </div>

                {/* campaign end Date */}
                <div>
                  <FormLabel>End Date <span className=' text-red-600'>*</span></FormLabel>
                  <Input
                    value={campaignData?.endDate}
                    onChange={(e) => setCampaignData({ ...campaignData, endDate: e.target.value })}
                    required
                    type="date"
                    slotProps={{
                      input: {
                        // min: '2018-06-07',
                        // max: '2018-06-14',
                      },
                    }}
                  />
                </div>

              </div>
              <FormLabel>Add designs to campaign <span className=' text-red-600'>*</span></FormLabel>
              <CorporateDesigns
                designArray={designArray}
                setDesignArray={setDesignArray}
                campaignData={campaignData}
                setCampaignData={setCampaignData}
              />
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}