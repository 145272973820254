// import axios from "axios";
import { toast } from "react-hot-toast";
import { API } from "../../backend";
import axios from "axios";
import { json } from "react-router-dom";
import { sendMailToDesigner } from "../../fetchData/sendMailDesigner";
import { get_User } from "../../fetchData/getsingleUser";


export const otpVerifyData = (signUpData) => async (dispatch) => {
  const storedSignupData = signUpData;
  dispatch({
    type: "GET_DATA_FOR_OTP",
    payload: storedSignupData,
  });
};

export const userInfo = (user) => async (dispatch, getState) => {
  // const
  const response = await fetch(`${API}auth/login`, {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 410) {
    const datares = await response.json();
    toast.error(datares.message);
    dispatch({
      type: "GET_USER_RESPONSE",
      payload: datares,
    });
  }
  if (response.status === 200) {
    const datares = await response.json();
    //check for cart too
    let cart = localStorage.getItem("cart")? JSON.parse(localStorage.getItem("cart")): [];
    if (cart && cart[0] === null) {
      cart = [];
    }
    if (cart.length !== 0) {
      const updCart = async () => {
        for (let i = 0; i < cart.length; i++) {
          const cartObject = {
            poster_obj_id: cart[i]._id,
            material_obj_id: cart[i].configuration._id,
            quantity: cart[i].quantity,
            removeCart: false,
          };
          const response2 = await fetch(`${API}auth/update_user_cart`, {
            method: "POST",
            body: JSON.stringify(cartObject),
            headers: {
              "Content-Type": "application/json",
              "x-access-token": datares.data.session_token,
            },
          });
        }
      };
      updCart()
        .then(() => {
          // console.log("initial cart updated")
        })
        .catch((error) => {
          console.log("no initial cart up", error);
        });
    }
    let wishlist = localStorage.getItem("wishlist")
      ? JSON.parse(localStorage.getItem("wishlist"))
      : [];
    if (!Array.isArray(wishlist)) {
      wishlist = [];
    }
    if (wishlist.length !== 0) {
      for (let i = 0; i < wishlist.length; i++) {
        let d = {
          add: true,
          poster_obj_id: wishlist[i]._id,
        };
        const response = await fetch(`${API}auth/add_user_details`, {
          method: "POST",
          body: JSON.stringify(d),
          headers: {
            "Content-Type": "application/json",
            "x-access-token": datares.data.session_token,
          },
        });
        const data = await response.json();
        if (data.status === 200)
          dispatch({
            type: "UPDATE_USER_ADDRESS_OR_WISHLIST",
            payload: data.data,
          });
      }
    }

    const response3 = await fetch(`${API}auth/get_user_details_by_id`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": datares.data.session_token,
      },
    });
    if (response3.status === 200) {
      const r = await response3.json();
      dispatch({
        type: "UPDATE_CART_BACKEND",
        payload: r.data[0].cart,
      });
      dispatch({
        type: "ADDWLIST",
        payload: r.data[0].wishList,
      });
    }
    if (response3.status === 410) {
      dispatch({
        type: "EMPTY_CART",
      });
    }

    dispatch({
      type: "GET_USER_RESPONSE",
      payload: datares,
    });
  }
};

export const userSignout = () => async (dispatch) => {
  // dispatch({
  //   type: "EMPTY_CART"
  // })
  dispatch({
    type: "USER_SIGNOUT",
  });
  dispatch({
    type: "ADDWLIST",
    payload: [],
  });
};

export const getUser = () => async (dispatch, getState) => {
  let response = "";
  const myuser = JSON.parse(localStorage.getItem('myuser'));

  if (myuser && myuser.token) {
    try {
      response = await fetch(`${API}auth/get_user_details_by_id`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": `${myuser.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("in landingpage", data)
        dispatch({
          type: "GET_USER_DETAIL",
          payload: data?.data[0],
        });
        dispatch({
          type: "UPDATE_CART_BACKEND",
          payload: data?.data[0].cart,
        });
      } else {
        console.error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("An error occurred while making the fetch request:", error);
    }
  } else {
    console.log("The userToken is not available in localStorage.");
  }
};


//send mail to designer
export const sendMail = (id) => async (dispatch) => {
  const res = await axios
    .get(`${API}designers/selectDesigner/${id}`)
  dispatch({
    type: "SEND_MAIL",
    payload: res.data,
  });
};

//getting single user
export const getSingle_User = (id) => async (dispatch) => {
  // const res = await get_User(id) 
  const res = await axios.get(`${API}auth/getsingleUser/${id}`)
  dispatch({
    type: "GET_SINGLE_USER",
    payload: res.data,
  });
};

export const updateUserAddressAndWishlist =
  (
    add,
    poster_obj_id,
    address,
    editAddress,
    addressID,
    address_pincode,
    userBillingAddress,
    editBillingAddress,
    billingAddressId
  ) =>
    async (dispatch, getState) => {
      const myuser = JSON.parse(localStorage.getItem('myuser'));
      let d = {
        add: add,
        address: address,
        poster_obj_id: poster_obj_id,
        editAddress: editAddress,
        addressID: addressID,
        address_pincode: address_pincode,
        userBillingAddress: userBillingAddress,
        editBillingAddress: editBillingAddress,
        billingAddressId: billingAddressId,
      };
      const response = await fetch(`${API}auth/add_user_details`, {
        method: "POST",
        body: JSON.stringify(d),
        headers: {
          "Content-Type": "application/json",
          "x-access-token": `${myuser?.token}`,
        },
      });
      const data = await response.json();

      if (data.status === 200)
        dispatch({
          type: "UPDATE_USER_ADDRESS_OR_WISHLIST",
          payload: data.data,
        });
      // let w = JSON.parse(local)
      // dispatch({
      //   type: "ADDWLIST",
      //   payload: data.data.wishList
      // })
    };

export const editUserAddress = (obj) => async (dispatch) => {
  dispatch({
    type: "EDIT_USER_ADDRESS",
    payload: obj,
  });
};

export const editUserBillingAddress = (obj) => async (dispatch) => {
  dispatch({
    type: "EDIT_USER_BILLING_ADDRESS",
    payload: obj,
  });
};

export const editUserSamegAddress = (obj) => async (dispatch) => {
  dispatch({
    type: "EDIT_SAME_ADDRESS",
    payload: obj,
  });
};

export const getUserOrders = () => async (dispatch, getState) => {
  try {
    const myuser = JSON.parse(localStorage.getItem('myuser'));
    const response = await fetch(`${API}orders/g`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${myuser?.token}`,
      },
    });
    const data = await response.json();
    if (data.status === 200) {
      dispatch({
        type: "GET_USER_ORDERS",
        payload: data.data,
      });
    }
  } catch (error) {
    console.error(error)
  }
};

export const removeWishList = (add, poster_obj_id) => async (dispatch) => {
  const myuser = JSON.parse(localStorage.getItem('myuser'));
  let d = {
    add: add,
    poster_obj_id: poster_obj_id,
  };
  const response = await fetch(`${API}auth/add_user_details`, {
    method: "POST",
    body: JSON.stringify(d),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${myuser?.token}`,
    },
  });
  const data = await response.json();
  const response1 = await fetch(`${API}auth/get_user_details_by_id`, {
    method: "GET",
    // body: JSON.stringify(email),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${myuser?.token}`,
    },
  });
  const data1 = await response1.json();
  dispatch({
    type: "REMOVE_WISHLIST",
    payload: data1.data[0],
  });
};

export const trackYourOrder = (shipment_id) => async (dispatch) => {
  try {
    // const userToken = JSON.stringify(localStorage.getItem("token"));
    const response = await fetch(
      `${API}orders/TrackOrder?order_id=${shipment_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //  "x-access-token": `${userToken}`
        },
      }
    );
    const data = await response.json();
    dispatch({
      type: "TRACK_YOUR_ORDER",
      payload: data.data,
    });
  } catch (error) {
    console.error(error)
  }
};

export const updateWishlistWithoutSignin = (data) => async (dispatch) => {
  dispatch({
    type: "ADDWLIST",
    payload: data,
  });
};

export const singleOrder = (order_id) => async (dispatch) => {
  try {
    const response = await fetch(
      `${API}orders/singleOrder?order_id=${order_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    let a = 267391166;
    // let a=data.data[0].shippingDetails.shipment_id
    const response1 = await fetch(`${API}orders/TrackOrder?order_id=${a}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //  "x-access-token": `${userToken}`
      },
    });
    const data1 = await response1.json();
    dispatch({
      type: "TRACK_YOUR_ORDER",
      payload: data1.data,
    });
    dispatch({
      type: "GET_SINGLE_ORDER",
      payload: data.data,
    });
  } catch (error) {
    console.error(error)
  }
};

export const storeSearchedString = (string) => {
  return {
    type: "STORE_STRING",
    payload: string,
  };
};

export const storeCroppingImgLink = (link) => {
  return {
    type: "SET_CROPPING_IMG_LINK",
    payload: link,
  };
};

// studio data

export const getStudioData = () => async (dispatch) => {
  const myuser = JSON.parse(localStorage.getItem('myuser'));
  if (myuser) {
    try {
      const response = await fetch(`${API}auth/get_studio`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${myuser?.token}`,
        },
      });
      const data = await response.json();
      dispatch({
        type: "STUDIO",
        payload: data,
      });
    } catch (error) {
      console.log(error)
    }
  }
  else {
    console.log("userToken not present")
  }

}

export const updateRoles = (payload) => async (dispatch) => {
  const myuser = JSON.parse(localStorage.getItem('myuser'));
  try {
    const response = await fetch(`${API}auth/update_studio_people_role`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${myuser.token}`,
      },
      body: JSON.stringify({
        uiid: payload.uiid,
        role: payload.role,
        email: payload.email,
      })
    });
  } catch (error) {
    console.error(error);
  }
}

export const removeUserfromStudio = (payload) => async (dispatch) => {
  const myuser = JSON.parse(localStorage.getItem('myuser'));
  try {
    const response = await fetch(`${API}auth/remove_people_from_studio`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${myuser.token}`,
      },
      body: JSON.stringify({
        uiid: payload.uiid,
        email: payload.email
      })
    });
  } catch (error) {
    console.error(error)
  }
}

export const inviteUserToStudio = (payload) => async (dispatch) => {
  const myuser = JSON.parse(localStorage.getItem('myuser'));
  try {
    const response = await fetch(`${API}auth/invite_people_to_studio`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${myuser?.token}`,
      },
      body: JSON.stringify({
        studioName: payload.studioName,
        studioId: payload.uiid,
        emails: payload.emails,
      })
    });
  } catch (error) {
    console.error(error);
  }
}

export const deleteStudio = (uiid) => async (dispatch, getState) => {
  const myuser = JSON.parse(localStorage.getItem("myuser"))
  try {
    const response = await fetch(`${API}auth/delete_studio`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${myuser.token}`,
      },
      body: JSON.stringify({ uiid: uiid })
    })
    if (response.ok) {
      // If the request was successful, update the state
      dispatch(getStudioData());
    } else {
      // If the request failed, handle the error
      console.error('Failed to delete studio');
    }
  } catch (error) {
    console.error(error)
  }
}

export const getUserStudios = async (email) => {
  try {
    const response = await fetch(`${API}/api/user/${email}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to fetch user studios');
    }
    return data;
  } catch (error) {
    console.error('Error fetching user studios:', error);
    throw error;
  }
};


// Action to fetch CSRF token
// export const fetchCsrfToken = () => async (dispatch) => {
//   const response = await fetch(`${API}csrf-token`, {
//     method: 'GET',
//     credentials: 'include',
//   });
//   const data = await response.json();
//   console.log(data, "checking csrf token");
//   dispatch({ type: 'CSRF_TOKEN', payload: data.csrfToken });
// };