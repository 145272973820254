import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CampCard from '../components/doityourself/src/components/campaigns/CampCard'
import { API, API1 } from '../backend'
import CampaignModel from '../components/doityourself/src/components/ownDesigns/createCampaign/CampaignModel'
import EditTempModel from '../components/doityourself/src/components/campaigns/editCampaign/EditCampModel'
import { toast } from 'react-toastify'
import { Puff } from 'react-loader-spinner'

export default function Campaign() {
  const [loading, setLoading] = useState(false)
  const userDetails = useSelector(state => state.user.userDetail)
  const getState = useSelector(state => state)
  const [camps, setCamps] = useState([])
  const businessId = JSON.parse(localStorage.getItem('businessId'))
  const [currentVarient, setCurrentVarient] = useState('My Campaigns')
  const varient = ["My Campaigns", "Team Campaigns"]
  const currentUser = JSON.parse(localStorage.getItem("myuser"))
  const [designArray, setDesignArray] = useState([])
  const [campaignData, setCampaignData] = useState({
    id: "",
    user: `${currentUser.id}`,
    title: "",
    desc: "",
    startDate: null,
    endDate: null,
    designs: []
  })
  const [adminDesigns, setAdminDesigns] = useState([])
  const [open, setOpen] = useState(false)

  const addUserCamp = async (getState) => {
    if (businessId) {
      axios.get(`${API}campaign/getCampaignByUser/${businessId}`)
        .then(({ data }) => setAdminDesigns(data))
    }
  }

  useEffect(() => {
    addUserCamp()
  }, [])

  //get cempaigns as par userid
  const getCamps = async (getState) => {
    setLoading(true)
    if (currentVarient === "My Campaigns") {
      await axios.get(`${API}campaign/getCampaignByUser/${userDetails?._id}`)
        .then(({ data }) => {
          setLoading(false)
          setCamps(data)
        })
    }
    else if (currentVarient === "Team Campaigns") {
      if (!businessId) {
        setLoading(true)
        //team campaign for admin
        await axios.get(`${API}campaign/getCampaigns`)
          .then(({ data }) => {
            let res = data?.filter((c) => userDetails?.team?.find((mail) => mail === c?.user?.emailid))
            setLoading(false)
            setCamps(res)
          })
      }
      else {
        setLoading(true)
        //team campaign for team members
        const currentAdmin = await axios.get(`${API}auth/getsingleUser/${businessId}`)
        const filteredList = await Promise.all(
          currentAdmin?.data?.team?.map(async (mail, getState) => {
            let userdata = await axios.get(`${API1}auth/getUserByMail/${mail}`)
            userdata = userdata?.data
            let res = await axios.get(`${API}campaign/getCampaignByUser/${userdata?._id}`)
            // const newRes = res?.find((data)=>data?.adminId === userdata?._id)
            return res?.data
          })
        )
        console.log('filtered Data is this', filteredList.flat())
        setCamps([...adminDesigns, ...filteredList.flat()])
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    getCamps()
    // if (businessId) {
    //   setCamps({
    //     ...camps,
    //     ...adminDesigns
    //   })
    // }
  }, [currentVarient])

  //create campaign
  const createNewCampaign = () => {
    const create = async (getState) => {
      await axios.post(`${API}campaign/createCamp`,
        campaignData)
        .then(({ data }) => {
          toast.success("New Campaign is created")
          getCamps()
        })
        .catch((err) => {
          alert("something went wrong")
        })
    }
    create()
  }

  //edit campaign
  const editCampaign = () => {
    const edit = async (getState) => {
      await axios.put(`${API}campaign/updateCamp/${campaignData?.id}?userId=${userDetails?._id}`, campaignData,)
        .then(({ data }) => {
          toast.success("Campaign is updated successfully")
          getCamps()
        })
        .catch((err) => {
          alert("something went wrong")
        })
    }
    edit()
  }



  return (
    <div className=' w-full'>
      <EditTempModel
        editCampaign={editCampaign}
        open={open}
        setOpen={setOpen}
        campaignData={campaignData}
        setCampaignData={setCampaignData}
        designArray={designArray}
        setDesignArray={setDesignArray}
      />

      <div className=' flex w-full flex-wrap justify-between gap-2 px-4 mb-[-10px] pt-3'>
        <div className=' flex gap-2 items-center'>
          {
            varient?.map((item) => (
              <div className={currentVarient === item ? ' cursor-pointer duration-200 transition-all bg-blue-950 text-white px-3 py-1.5 rounded-md border-[1px] border-indigo-200' : 'cursor-pointer duration-200 transition-all bg-white border-[1px] border-indigo-200 text-black px-3 py-1.5 rounded-md'}
                onClick={() => setCurrentVarient(item)}
              >{item}</div>
            ))
          }
        </div>
        <CampaignModel
          designArray={designArray}
          setDesignArray={setDesignArray}
          createNewCampaign={createNewCampaign}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
        />
      </div>
      {
        !loading ?
          <div className=' grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1 gap-2 p-6'>
            {
              camps?.map((c, idx) => (
                <CampCard
                  designArray={designArray}
                  setDesignArray={setDesignArray}
                  setCampaignData={setCampaignData}
                  campaignData={campaignData}
                  setOpen={setOpen}
                  key={idx} c={c} idx={idx} />
              ))
            }

          </div> :
          <div
            style={{
              transform: 'translate(-50%,-50%)'
            }}
            className=' absolute top-[50%] left-[50%] flex justify-center items-center'>
            <Puff
              visible={true}
              height="90"
              width="90"
              color="#14366e"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
      }
      {
        !loading && camps?.length === 0 &&

        <div
          style={{
            transform: 'translate(-50%,-50%)'
          }}
          className=' absolute top-[50%] left-[50%] flex justify-center items-center'>
          <p className=' font-semibold text-xl'>No Data</p>
        </div>
      }
    </div>
  )
}
