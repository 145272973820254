let initialState = {
  scale: false,
  dimensions: {
    height: null,
    width: null,
  },
};

const ScaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SCALE_ON":
      return {
        ...state,
        scale: action.payload.scale,
      };
    case "SET_SVG_DIMENSIONS":
      return {
        ...state,
        dimensions: {
          height: action.payload.height,
          width: action.payload.width,
        },
      };
    default:
      return state;
  }
};

export default ScaleReducer;
